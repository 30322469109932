import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend
} from "chart.js";

import * as CONST from "../../../includes/Constant";
import BlockLoading from "../../../blocks/BlockLoading";
import { S_FORMAT_INR_CURRENCY_AND_AMOUNT } from "../../../includes/staticData";
import { setGlobalModal } from "../../modal/GlobalModal";
import ViewClient from "../../modal/client/ViewClient";
import { AuthContext } from "../../../context/AuthContext";
import useAuthInfo from "../../../../hooks/IsAdmin";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const TopPayingClientsChart = ({ searchParams }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [authUserData, setAuthUserData] = useContext(AuthContext);
  const { isAdmin, eDepartmentId } = useAuthInfo();

  useEffect(() => {
    fetchTopPayingClients();
  }, [searchParams]);

  const fetchTopPayingClients = async () => {
    setLoading(true);
    try {
      const response = await axios.get(CONST.DASH_TOP_PAYING_CLIENTS, {
        params: {
          year: searchParams.year || "",
          department_id: searchParams.department_id || "",
          month: searchParams.month || "",
        }
      });
      setData(response.data?.data || []);
    } catch (error) {
      console.error("Error fetching data", error);
      setData([]);
    } finally {
      setLoading(false);
    }
  };

  const chartData = {
    labels: data.map((item) => item.client_name),
    datasets: [
      {
        label: "Total Received",
        data: data.map((item) => item.total_received),
        backgroundColor: "#0088FE", // Tailwind blue-500
        borderRadius: 0,
        barThickness: 20
      }
    ]
  };

  const options = {
    indexAxis: "y", // horizontal bar
    responsive: true,
    plugins: {
      legend: { display: false },
      tooltip: {
        callbacks: {
          label: function (context) {
            return S_FORMAT_INR_CURRENCY_AND_AMOUNT(context.raw);
          }
        }
      }
    },
    scales: {
      x: {
        ticks: {
          callback: (value) => S_FORMAT_INR_CURRENCY_AND_AMOUNT(value)
        },
        grid: {
          drawOnChartArea: false
        }
      },
      y: {
        grid: {
          drawOnChartArea: false
        }
      }
    }
  };

  return (
    <div className="card flex-fill">
      <div className="card-header">
        <h5 className="card-title mb-0">Top Paying Clients</h5>
      </div>
      <div className="card-body">
        {loading ? (
          <BlockLoading />
        ) : data.length > 0 ? (
          <Bar data={chartData} options={options} height={150} />
        ) : (
          <h5 className="text-muted text-center">No records found.</h5>
        )}
      </div>
    </div>
  );
};

export default TopPayingClientsChart;
